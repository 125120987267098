import request from '@/utils/request';

// 活动列表
export const getActivityList = params => request.post(`study/activity/list`, params)

// 活动信息
export const getActivityInfo = taskId => request.get(`study/activity/${taskId}/info`)

// 获取活动信息收集字段
export const getActivityField = params => request.post(`study/activity/detailfield`, params)

// 保持活动信息收集字段
export const submitActivityField = params => request.post(`study/activity/membersubmit`, params)